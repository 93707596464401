var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('base-header',[_c('div',{attrs:{"slot":"page-header"},slot:"page-header"},[_c('h2',{staticClass:"font-weight-black primary--text"},[_vm._v("Wallets")])])]),_c('v-data-table',{staticClass:"elevation-1",class:[_vm.selected && 'selected'],attrs:{"height":"60vh","headers":_vm.headers,"loading":_vm.loading,"items":_vm.wallets,"page":_vm.pagination.page,"footer-props":{
                        'items-per-page-options':[10,30,50],
                        itemsPerPageText:''
                      },"server-items-length":_vm.pagination.totalItems,"items-per-page":_vm.pagination.rowsPerPage,"loading-text":"Loading... Please wait","item-key":"name"},on:{"update:items-per-page":_vm.itemsPerPageChange,"update:page":_vm.pageChange},scopedSlots:_vm._u([{key:"item.tokensCurrency",fn:function(ref){
                      var item = ref.item;
return _vm._l((item.tokenCurrencies),function(currency){return _c('div',[_c('div',{class:("" + (currency!== item.cryptoCurrency ? 'red--text':''))},[_vm._v(_vm._s(currency))])])})}},{key:"item.owner",fn:function(ref){
                      var item = ref.item;
return [(!!item.owner)?_c('router-link',{attrs:{"to":'/users/'+item.owner.id}},[_vm._v(" "+_vm._s(item.owner ? '@' + item.owner.userInfo.nickname : '')+" ")]):_vm._e()]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }