<template>
  <v-container>
    <base-header>
      <div slot="page-header">
        <h2 class="font-weight-black primary--text">Wallets</h2>
      </div>
    </base-header>
    <v-data-table v-model="selected"
                  height="60vh"
                  :headers="headers"
                  :loading="loading"
                  :items="wallets"
                  :page="pagination.page"
                  :footer-props="{
                          'items-per-page-options':[10,30,50],
                          itemsPerPageText:''
                        }"
                  @update:items-per-page="itemsPerPageChange"
                  @update:page="pageChange"
                  :server-items-length="pagination.totalItems"
                  :items-per-page="pagination.rowsPerPage"
                  loading-text="Loading... Please wait"
                  item-key="name"
                  :class="[selected && 'selected']"
                  class="elevation-1">
      <template v-slot:item.tokensCurrency="{ item }">
        <div v-for="currency in item.tokenCurrencies">
          <div :class="`${currency!== item.cryptoCurrency ? 'red--text':''}`">{{ currency }}</div>
        </div>
      </template>

      <template v-slot:item.owner="{ item }">
        <router-link :to="'/users/'+item.owner.id" v-if="!!item.owner">
          {{ item.owner ? '@' + item.owner.userInfo.nickname : '' }}
        </router-link>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import BaseCard from "../../components/Base/BaseCard.vue";
import BaseHeader from "../../components/Base/BaseHeader.vue";
import axios from "axios";

export default {
  name: 'wallets',
  components: {
    BaseCard,
    BaseHeader
  },
  methods: {
    pageChange(newPage) {
      this.pagination.page = newPage
      this.fetchWallets()
    },
    itemsPerPageChange(newPerPage) {
      this.pagination.rowsPerPage = newPerPage
      this.fetchWallets()
    },
    doSearch() {
      if (this.search) {
        this.fetchWallets(this.search)
      } else {
        this.fetchWallets()
      }
    },
    fetchWallets(searchString = undefined) {
      this.loading = true;
      let url = process.env.VUE_APP_BASE_API_URL + '/admin/wallet';
      axios.get(url, {
        params: {
          page: this.pagination.page - 1,
          size: this.pagination.rowsPerPage,
          sort: 'created,desc',
          search: searchString,
        }
      }).then(resp => {
        this.wallets = resp.data.content.map(wall => {
          let tokenCurrencies = [];
          wall.tokens.map(tok => {
            if (tokenCurrencies.indexOf(tok.currency) === -1) {
              tokenCurrencies.push(tok.currency);
            }
          })
          return {
            ...wall,
            tokenCurrencies
          }
        })
        this.pagination.totalItems = resp.data.totalElements
      }).finally(() => {
        this.loading = false;
      })
    }
  },
  created() {
    this.fetchWallets()
  },
  data() {
    let data = {
      pagination: {
        descending: true,
        sortBy: 'created',
        rowsPerPage: 10,
        page: 1,
        totalItems: 0,
      },
      search: null,
      loading: false,
      wallets: [],
      selected: [],
      headers: [
        {
          text: 'Address',
          sortable: false,
          value: 'address'
        },
        {
          text: 'Owner',
          sortable: false,
          value: 'owner'
        },
        {
          text: 'Chain',
          sortable: false,
          value: 'cryptoCurrency'
        },
        {
          text: 'Tokens currency',
          sortable: false,
          value: 'tokensCurrency'
        },
      ],
    }
    return data;
  },
};
</script>
<style scoped>
.selected {
  cursor: pointer;
}
</style>
